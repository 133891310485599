<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 500 500" *ngIf="type === 'settings'">
    <path d="M179.92,29.17C190,29.33,197.58,33.6,203.78,41c12.39,14.88,28.42,22.57,47.79,22.35,19.15-.21,34.91-8.09,47.12-22.85,7-8.44,15.87-12.54,26.66-11,5.21.74,10.3,2.9,15.23,4.94a227,227,0,0,1,59.06,35.65c10.54,8.76,13.81,20.42,9.41,33.27-13.11,38.31,8,74.74,47.79,82.43,14.19,2.75,22.18,11,24.85,25.13a213.55,213.55,0,0,1-.34,79.53c-2.37,12.66-12.12,21.93-25.12,23.91-40.31,6.15-63.07,44.91-48.79,83.11,5.15,13.77,2,25.61-9.3,34.93a234.25,234.25,0,0,1-58.72,35.3c-14.62,6.11-27,3-36.91-9.3-21.83-27.11-59.46-31.89-86.06-10.68a78.25,78.25,0,0,0-12,12.71c-9.23,11.67-21.72,15.36-35.58,10.08A234.4,234.4,0,0,1,103,432.27c-10.73-8.83-13.56-21.52-7.84-35.2C110.26,360.84,90,321.4,51.88,313c-2.7-.59-5.45-.9-8.18-1.34a28.68,28.68,0,0,1-24.16-24.12,227.27,227.27,0,0,1,1.07-78.64c2.67-14.22,12-23.11,26.65-25.14,40.8-5.68,64-43.91,50.19-82.61-5.08-14.24-1.6-26.19,10.33-35.48a231.78,231.78,0,0,1,61.51-34.31,42.66,42.66,0,0,1,6.08-1.75A35.62,35.62,0,0,1,179.92,29.17Zm71.21,299.9c43.47-.17,78.09-35.25,78-79-.12-42-35.42-77.25-77.28-77.2-44.32,0-79,34.77-78.93,79C173,294.22,208.46,329.24,251.13,329.07Z"/>
</svg>


<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 463.04 502.01" *ngIf="type === 'ranks'">
    <path d="M473,56.4a42,42,0,0,0-30.05-12.26l-82.75.44-2.79-11.9A35.84,35.84,0,0,0,314.33,6L52.15,67.32a35.88,35.88,0,0,0-26.72,43.07l86.36,368.93a35.75,35.75,0,0,0,43.06,26.73l42.73-10,247.77-1.33a42.45,42.45,0,0,0,42.17-42.63l-2-365.77A42.14,42.14,0,0,0,473,56.4Zm-320.86,438a23.85,23.85,0,0,1-28.65-17.78h0L37.11,107.65A23.85,23.85,0,0,1,54.89,79L317.06,17.63a24.12,24.12,0,0,1,5.48-.63,23.87,23.87,0,0,1,23.17,18.41l86.36,368.94A23.83,23.83,0,0,1,414.3,433L196,484.1a5.8,5.8,0,0,0-1.28.3Zm293.16-11.65-195.24,1,167-39.09a35.84,35.84,0,0,0,26.73-43.06h0L363,56.57l80-.43h.17a30.39,30.39,0,0,1,30.4,30.24l2,365.77a30.43,30.43,0,0,1-30.24,30.57Z"
          transform="translate(-24.48 -5)"/>
    <path d="M244.33,152.55a56.31,56.31,0,0,1,27,15.27q11.1,11.27,15.2,28.79,13.88,59.46-54.8,78.29a5.61,5.61,0,0,0-4,6.59l5.84,26.9a5.59,5.59,0,0,1-4.19,6.63l-.4.09a5.59,5.59,0,0,1-6.69-4.07l-9.18-36.29a5.58,5.58,0,0,1,4.14-6.81l.3-.07q31.26-7.31,47.43-22.47t9-45.85q-5.06-21.62-21.78-31.27t-38.94-4.46q-22.5,5.25-32.92,20.62-9.13,13.45-6.56,31a5.59,5.59,0,0,1-4.24,6.31l-1.68.4a5.59,5.59,0,0,1-6.8-4.59,55.76,55.76,0,0,1,2-26,52.28,52.28,0,0,1,17.34-24.69,74.15,74.15,0,0,1,30-14.09Q228.46,148.55,244.33,152.55ZM231.87,363.71a11.7,11.7,0,0,1,3.61-21.48,11,11,0,0,1,8.64,1.37A12.35,12.35,0,0,1,247.94,360,11,11,0,0,1,240.8,365,11.35,11.35,0,0,1,231.87,363.71Z"
          transform="translate(-24.48 -5)"/>
</svg>
