<div class="page-container">

    <app-feed></app-feed>

    <table-infos class="table-infos"
                 [tableName]="tableName"
                 [players$]="players$"
                 [time$]="playDuration$"></table-infos>


    <poker-sidepots [sidePots$]="sidePots$"></poker-sidepots>

    <div class="table__action"
         *ngIf="notification.action$ | async as currentAction"
         [class.error]="currentAction.status === 'error'">{{currentAction.message}}</div>

    <div class="ready-container"
         *ngIf="(gameStatus$ | async) === 'waiting' && (player$ | async) && (players$ | async).length > 1 && (player$ | async).id === (players$ | async)[0]?.id">
        <div class="ready-container__message">Everyone's ready?</div>
        <button class="button" (click)="startGame()">Start</button>
    </div>


    <!--    <div class="ready-container" *ngIf="(gameStatus$ | async) == 'ended' && player$ | async">-->
    <!--        <div class="ready-container__message">Go next?</div>-->
    <!--        <button class="button" (click)="startGame()">Start</button>-->
    <!--    </div>-->


    <div class="game_controls" *ngIf="player$ | async as player">

        <div *ngIf="(gameStatus$ | async) === 'ended' && !player.folded && !player.showCards">
            <button class="button" (click)="showCards()">Show Cards</button>
        </div>
        <game-controls @controlsFadeSlideInOut
                       *ngIf="(gameStatus$ | async) === 'started' && isCurrentPlayer && player"
                       [player]="player"
                       [maxBet$]="maxBet$"
                       [pot]="pot$ | async"
                       [bigBlind]="20"
                       (checked)="check()"
                       (called)="call()"
                       (bet)="bet($event)"
                       (folded)="fold()"></game-controls>
    </div>

    <div class="table">
        <div class="stack card-place">
            <poker-card *ngFor="let card of getArray(45); let i = index;"
                        figure="back"
                        [ngStyle]="{'top.px': -0.5*i +5 }"></poker-card>
        </div>
        <div class="board card-place">
            <poker-card @cardsFadeIn
                        *ngFor="let card of (board$ | async); index as i;  trackBy:trackCardValue;"
                        [figure]="card.figure"
                        [value]="card.value"></poker-card>
        </div>
        <div class="players">
            <poker-player *ngFor="let player of (players$ | async); let i = index;"
                          [class.client-player]="player.id === (player$|async)?.id"
                          [player]="player"
                          [seat]="getPlayerSeat(i)"
                          [dealer]="player.id === (dealerPlayerID$ | async)"
                          [playing]="player.id === (currentPlayerID$ | async) && (gameStatus$ | async) === 'started'"
                          (voteKick)="voteKick(player.id)"></poker-player>
        </div>
        <div class="pot" *ngIf="pot$ | async as pot">
            <div class="pot__value">
                {{pot}}€
            </div>
            <div class="jetons">
                <poker-chips [amount]="pot" [type]="100"></poker-chips>
            </div>
            <div class="jetons">
                <poker-chips [amount]="pot" [type]="50"></poker-chips>
            </div>
            <div class="jetons">
                <poker-chips [amount]="pot" [type]="10"></poker-chips>
            </div>
            <div class="jetons">
                <poker-chips [amount]="pot" [type]="5"></poker-chips>
            </div>
            <div class="jetons">
                <poker-chips [amount]="pot" [type]="1"></poker-chips>
            </div>
        </div>
    </div>

    <div class="overlay" [class.visible]="showOverlay && inProduction">
        <h2>Sorry, but the table <span class="is-primary-text">{{tableName}}</span> does not exist</h2>
        <a routerLink="/" class="link">Back to home?</a>
    </div>
</div>


