<h2>Table Settings</h2>

<div [formGroup]="settingsForm">

    <div class="setting">
        <label class="setting__name">
            Game Type
            <select formControlName="type">
                <option *ngFor="let type of gameTypes" [value]="type.value" [disabled]="type.disabled">{{type.name}}</option>
            </select>
        </label>
        <div class="divider"></div>
    </div>

    <div class="setting">
        <label class="setting__name">
            Show on Public Table
            <app-toggle class="setting__input toggle" formControlName="isPublic"></app-toggle>
        </label>
        <div class="divider"></div>
    </div>

    <div class="setting">
        <label class="setting__name">
            Allow Spectators
            <app-toggle class="setting__input toggle" formControlName="spectatorsAllowed"></app-toggle>
        </label>
        <div class="divider"></div>
    </div>


    <div class="setting" formGroupName="table">
        <label class="setting__name disabled">
            Allow Re-Buys
            <app-toggle class="setting__input toggle" formControlName="rebuy"></app-toggle>
        </label>
        <div class="setting__description">If a player has no chips left to continue, allow them to rebuy.</div>

        <div class="divider"></div>

        <label class="setting__name">
            Auto close table
            <app-toggle class="setting__input toggle" formControlName="autoClose"></app-toggle>
        </label>
        <div class="setting__description">When the game is over (only one has chips left) the table will be removed.
        </div>
        <div class="divider"></div>

<!--        <label class="setting__name">-->
<!--            Auto fold-->
<!--            <app-toggle class="setting__input toggle" formControlName="autoFold"></app-toggle>-->
<!--        </label>-->
<!--        <div class="setting__description">-->
<!--            A player has 15 seconds for the turn. Otherwise it will be folded automatically.-->
<!--        </div>-->
<!--        <div class="divider"></div>-->
    </div>


    <div class="setting">
        <label class="setting__name disabled">
            Play Ambient Music
            <app-toggle class="setting__input toggle" formControlName="music"></app-toggle>
        </label>
        <div class="setting__description">Some casino vibes will be played.</div>
        <div class="divider"></div>
    </div>


    <div class="setting">
        <label class="setting__name">
            Player Chips
            <input class="setting__input input" size="4" formControlName="chips"/>
        </label>
        <div class="divider"></div>
    </div>


    <div class="setting" formGroupName="blinds">
        <label class="setting__name">
            Small Blind
            <input class="setting__input input" size="4" formControlName="small"/>
        </label>
        <label class="setting__name">
            Big Blind
            <input class="setting__input input" size="4" formControlName="big"/>
        </label>
        <label class="setting__name disabled">
            Blind Duration
            <input class="setting__input input" size="4" formControlName="duration"/>
        </label>
        <div class="setting__description">Define the seconds after blinds will be increased > 0, otherwise fixed.</div>

        <div class="divider"></div>
    </div>

    <div class="setting" formGroupName="turn">
        <label class="setting__name">
            Turn Time
            <input class="setting__input input" size="4" placeholder="∞" formControlName="time"/>
        </label>
        <div class="setting__description">Define the seconds > 0 a player has time for the turn, otherwise unlimited.
        </div>

        <div class="divider"></div>
    </div>


    <div class="setting" formGroupName="players">
        <label class="setting__name">
            Max Players
            <input class="setting__input input" size="4" formControlName="max"/>
        </label>
        <div class="divider"></div>
    </div>
</div>
