<ng-container *ngIf="{ maxBet: maxBet$ | async, betAmount: betAmount$ | async } as data">
    <div class="controls" *ngIf="player">
        <button class="button danger" (click)="onFold()">Fold</button>

        <button *ngIf="data.maxBet === 0 || (player.bet?.type === BetType.BigBlind && data.maxBet === player.bet?.amount); else callBtn"
                class="button check"
                (click)="onCheck()">Check
        </button>
        <ng-template #callBtn>
            <button *ngIf="data.maxBet !== player.bet?.amount" class="button" (click)="onCall()">
                Call ({{ hasToAllIn(data.maxBet) ? 'All In': getCallAmount(data.maxBet) }})
            </button>
        </ng-template>

        <div *ngIf="player.chips > 0 && !hasToAllIn(data.maxBet)" class="controls__bet" >

            <div class="controls__bet__amount__wrapper">
                <div class="controls__bet__popup" *ngIf="showPopup" @fadeInOut>
                    <div class="controls__bet__popup-background"></div>

                    <div class="controls__bet__chips">
                        <div class="controls__bet__chip" (click)="addBet(1)">
                            <img class="controls__bet__chip-asset" src="/assets/chip_gray_top.png" alt="1 chips"/>
                        </div>
                        <div class="controls__bet__chip" (click)="addBet(5)">
                            <img class="controls__bet__chip-asset" src="/assets/chip_lightblue_top.png" alt="5 chips"/>
                        </div>
                        <div class="controls__bet__chip" (click)="addBet(10)">
                            <img class="controls__bet__chip-asset" src="/assets/chip_red_top.png" alt="10 chips"/>
                        </div>
                        <div class="controls__bet__chip" (click)="addBet(50)">
                            <img class="controls__bet__chip-asset" src="/assets/chip_biege_top.png" alt="50 chips"/>
                        </div>
                        <div class="controls__bet__chip" (click)="addBet(100)">
                            <img class="controls__bet__chip-asset" src="/assets/chip_black_top.png" alt="100 chips"/>
                        </div>
                    </div>

                    <div class="controls__bet__templates">
                        <button class="controls__bet__template button"
                                *ngFor="let tmpl of betTemplates"
                                (click)="setBetFromTemplate(tmpl)">
                            {{tmpl.title}}
                        </button>
                    </div>

                </div>

                <div class="controls__bet__amount" (click)="togglePopup();">
                    <div class="controls__bet__amount__chips">
                        <poker-chips [amount]="data.betAmount" [type]="10" direction="bottom"></poker-chips>
                        <poker-chips [amount]="data.betAmount" [type]="50" direction="bottom"></poker-chips>
                        <poker-chips [amount]="data.betAmount" [type]="100" direction="bottom"></poker-chips>
                    </div>
                    <div class="controls__bet__amount__value">{{data.betAmount}}</div>
                </div>
            </div>

            <input (input)="onBetChange($event)"
                   (wheel)="onMouseWheel($event)"
                   [value]="data.betAmount"
                   type="range"
                   step="10"
                   [min]="data.maxBet === 0 ? minimumBet : getCallAmount(data.maxBet)"
                   [max]="getBetableAmount()"/>
            <button class="button" (click)="onBet();">Bet</button>
            <button class="button is-transparent clear" (click)="clearBet();">Reset</button>
        </div>
    </div>
</ng-container>
